<template>
	<div class="about">
		<div class="ban"></div>
		
		<div class="tabbg">
			<div class="picBox">
				<img src="@/assets/images/about/p1.jpg" alt="" class="pic" id="page1">
				<img src="@/assets/images/about/p2.jpg" alt="" class="pic" id="page2">
				<img src="@/assets/images/about/p3.jpg" alt="" class="pic" id="page3">
			</div>
		</div>
			
		
		<footers></footers>
	</div>
</template>

<script type="text/javascript">
	import footers from '@/components/foot.vue'
	import { createApp } from 'vue';
	
	
	
	
	export default {
		data() {
			return {
				active: "1",
				scrollArr:[0,620,1100],
			}
		},
		
		components:{
			footers,
		},
		methods:{
			
		},
		mounted(){
			 this.$nextTick(() => {
			    if(this.$route.query.page){
			    	this.active = this.$route.query.page
			    	
			    	setTimeout(()=>{
			    		document.documentElement.scrollTop = this.scrollArr[this.active-1]
			    	},100)
			    }
			  })
			
			
			
		},
		watch:{
			$route(){
				if(this.$route.query.page){
					this.active = this.$route.query.page
					setTimeout(()=>{
						document.documentElement.scrollTop = this.scrollArr[this.active-1]
					},100)
				}
			},
		},
		
	}
</script>
<style scoped>
	.ban {background: url(../assets/images/about/ban2.jpg) no-repeat center;height: 256px;;}

	:deep(.van-tabs--line .van-tabs__wrap){height: 64px;}
	:deep(.tabs .van-tabs__nav--line){justify-content: center;background: #F3F3F6;}
	:deep(.tab ) {width: 165px;flex: none;margin: 0 110px;}
	:deep(.tab span) {height: 62px;line-height: 62px;font-size: 20px;color: #222222;}
	:deep(.van-tabs__line){background: #F5630B;width: 40px !important;height: 2px;}
	:deep(.van-tab--active span){color: #F5630B;}
	:deep(.tab span:hover){color: #F5630B;}
	
	.txt1 {margin: 20px 0 60px;}
	.items{text-align: center;padding-top: 57px;}
	.cont .item{display: inline-block;margin: 0 63px;}
	.cont .item img{width: 350px;height: 350px;background: #E7E7E7;border-radius: 12px;padding: 25px;}
	.padt12{padding-top: 12px;}
	.cont .item .txt{font-size: 16px;line-height: 30px;color: #3D3D3D;margin-top: 24px;}
	.txt1 p {font-size: 14px;line-height: 30px;color: #3D3D3D;text-indent: 32px;}
	.tit{font-size: 16px;color: #222222;line-height: 22px;margin-top: 42px;padding-left: 8px;position: relative;}
	.tit::after{content: '';width: 2px;height: 14px;background:#2973FF;position: absolute;left: 0;top:4px;;}
	.padb150{padding-bottom: 150px;}
	.tabbg{background: #fff;}
	.picBox{width: 1366px;margin: 0 auto;padding: 50px 0 0;}
	.picBox .pic{width: 100%;margin-bottom: 80px;}
</style>
